const routes = {
  LANDING: '/',
  SIGN_IN: '/signin',
  PASSWORD_RESET: '/amnesia',
  HOME: '/home',
  ACCOUNT: '/account',
  ADMIN: '/admin',
}

export default routes
